import { GridColDef, GridFilterItem, GridSortItem } from "@mui/x-data-grid";
import { SelectOption } from "./types";
import dayjs from "dayjs";
import StringUtils from "./StringUtils";
import ApiProvider from "./ApiProvider";

const INITIAL_PERIOD = true;

/**
 * @param schema - the schema object that the current schema should be linked to.
 * @param shownFieldName - internal name (def.field) of the field to reference in the specified schema.
 * @param getApiMethod - optionally provides a preferred api method to use for fetching rows to display
 *      to the user as options, instead of using the generic getData api method.
 */
export type FieldLinkInfo = {
    schema: SchemaDefinition,
    shownFieldName: string,
    getApiMethod?: () => (tableName: string, token: string, pageSize: number, pageOffset: number, filters: Array<GridFilterItem>,
        sort: Array<GridSortItem>) => Promise<Array<{[key: string]: any}>>
}

/**
 * @param colDef - base definition of the schema. Used for adding new rows, and in the Database view of the table.
 * @param userColDef - optional definition of the schema, shown in tables filtered by user_id. This is useful because
 *      the API returns "Populated" objects, which contain more information than the base colDef schema.
 * @param hiddenColNames - list of names of the columns to hide from the table. Affects both colDef and userColDef.
 *      Does not do anything if the authenticated user is an Administrator.
 * @param additionalInfo - object containing more information about the fields defined in colDef.
 * @param name - name of the schema definition.
 * @param plural - plural of the name. This is used to determine how to compose API calls for routes that use the plural name.
 * @param display - how the name is displayed to the user, usually in a DataTableController Select.
 * @param requiredGroups - an array of groups of fields that allows for defining mutually exclusive groups of required fields.
 *      Also allows specifying a predicate to determine which group should be brought up and available to the user in the edit modal.
 * @param booleanEnabledGroups - an array of objects that define field groups which are enabled and/or disabled based on
 *      the value of a specified boolean field, within the same form.
 * @param renderOrder - array of field names placed in the exact order that they should be rendered in the addItemModal.
 *      Does not affect the render order of requiredGroups.
 */
export type SchemaDefinition = {
    colDef: GridColDef[],
    userColDef?: GridColDef[],
    useUserColDefForFormIfUserIdIsPresent?: boolean,
    hiddenColNames: string[],
    additionalInfo?: {[key: string]: AdditionalColInfo},
    name: string,
    plural: string,
    display: string,
    displaySingular: string,
    requiredGroups?: { groupName: string, fieldNames: string[], isEnabledForEdit: (row: any) => boolean }[][],
    booleanEnabledGroups?: {
        field: string,
        value: boolean,
        fieldGroup: string[]
    }[],
    renderOrder?: string[]
}

/**
 * @param inputMode - further specifies the data type of the text field.
 * @param validationPattern - custom pattern used for validating the input.
 * @param optional - if set to true, the field won't be marked as mandatory, in the "Add Row" form, and won't be
 *      required to submit such form.
 * @param fieldLinkInfo - contains information to link the specified field (usually an ID field) to objects of the specified schema.
 *      This is converted into an Autocomplete component which allows you to search by the specified shownFieldName, and
 *      extract the ID of the row whose shownFieldName matches the selected input.
 * @param possibilities - allows specifying an array of allowed inputs. Useful for making enums.
 * @param automatic - if set to true, the field will be marked as automatic. This means that it won't show up to the user
 *      trying to add a row in the table, as its value is already pre-determined, or will be determined by the back end.
 * @param defaultValue - a field marked as automatic will take on the specified defaultValue when submitted.
 *      If automatic is set to true, and no defaultValue is specified, the handling of that field will be left to the back end.
 * @param dateConfiguration - an object containing boolean flags for date pickers.
 */
export type AdditionalColInfo = {
    inputMode?: "search" | "text" | "none" | "email" | "tel" | "url" | "numeric" | "decimal",
    validationPattern?: string,
    optional?: boolean,
    fieldLinkInfo?: FieldLinkInfo,
    possibilities?: SelectOption[],
    automatic?: boolean,
    defaultValue?: any,
    dateConfiguration?: {
        disablePast?: boolean,
        disableFuture?: boolean
    }
}

const UuidValidationPattern = "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$";
const NumericValidationPattern = "^[0-9]*$";

const DateGetter = (value: any, row: any): any => {
    return value ? new Date(value) : undefined;
}

const DateFormatter = (value: any, row: any): any => {
    return value ? dayjs(value).format("DD/MM/YYYY") : undefined;
}

const UserRoleStringGetter = (value: any, row: any): any => {
    return [row.team_member && "Team Member", row.manager && "Manager", row.hr && "HR", row.data_manager && "Data Manager",
        row.skills_user && "Skills User", row.admin && "Admin"]
            .filter(item => item !== false).join(", ");
}

const Uuid: AdditionalColInfo = {
    validationPattern: UuidValidationPattern
}

const UserId: AdditionalColInfo = {
    ...Uuid,
    automatic: true
}

const Numeric: AdditionalColInfo = {
    inputMode: "numeric",
    validationPattern: NumericValidationPattern
}

const Url: AdditionalColInfo = {
    inputMode: "url"
}

export const CertificateSchema: SchemaDefinition = {
    name: "Certificate",
    plural: "Certificates",
    display: "Certificazioni",
    displaySingular: "Certificazione",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "creator_id": {
            automatic: true
        },
        "creation_date": {
            automatic: true
        },
        "level": Numeric,
        "validity": Numeric,
        "description": {
            inputMode: "text",
            optional: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "creator_id",
            "headerName": "ID Creatore",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "name",
            "headerName": "Nome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "company",
            "headerName": "Azienda Erogatrice",
            "type": "string",
            "flex": 1
        },
        {
            "field": "level",
            "headerName": "Livello",
            "type": "number",
            "flex": 1
        },
        {
            "field": "validity",
            "headerName": "Validità (mesi)",
            "type": "number",
            "flex": 1
        },
        {
            "field": "creation_date",
            "headerName": "Data Creazione",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "description",
            "headerName": "Descrizione",
            "type": "string",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "creator_id"]
}

const CertificateAcquiredSchema: SchemaDefinition = {
    name: "CertificateAcquired",
    plural: "CertificatesAcquired",
    display: "Certificazioni Acquisite",
    displaySingular: "Certificazione Acquisita",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "certificate_id": {
            fieldLinkInfo: {
                schema: CertificateSchema,
                shownFieldName: "name"
            }
        },
        "user_id": UserId,
        "url": Url,
        "state": {
            automatic: true,
            possibilities: [
                { name: "Acquisita", value: "acquired" },
                { name: "Scaduta", value: "expired", color: "warning" }
            ]
        },
        "description": {
            inputMode: "text",
            optional: true
        },
        "acquiry_date": {
            dateConfiguration: {
                disableFuture: true
            }
        },
        "expiry_date": {
            automatic: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "certificate_id",
            "headerName": "ID Certificazione",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "user_id",
            "headerName": "ID Utente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "acquiry_date",
            "headerName": "Data Acquisizione",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "expiry_date",
            "headerName": "Data Scadenza",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "state",
            "headerName": "Stato",
            "type": "string",
            "flex": 1,
            "sortable": false
        },
        {
            "field": "url",
            "headerName": "URL",
            "type": "string",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "certificate_id", "user_id"]
}

// Additional fields from CertificateAcquiredPopulated
CertificateAcquiredSchema.userColDef = [
    ...CertificateAcquiredSchema.colDef.filter(col => col.field !== "user_id"),
    {
        "field": "name",
        "headerName": "Nome",
        "type": "string",
        "valueGetter": (value, row) => row.certificate.name,
        "flex": 1
    },
    {
        "field": "company",
        "headerName": "Azienda Erogatrice",
        "type": "string",
        "valueGetter": (value, row) => row.certificate.company,
        "flex": 1
    },
    {
        "field": "level",
        "headerName": "Livello",
        "type": "number",
        "valueGetter": (value, row) => row.certificate.level,
        "flex": 1
    },
    {
        "field": "validity",
        "headerName": "Validità (mesi)",
        "type": "number",
        "valueGetter": (value, row) => row.certificate.validity,
        "flex": 1
    },
    {
        "field": "description",
        "headerName": "Descrizione",
        "type": "string",
        "valueGetter": (value, row) => row.certificate.description,
        "flex": 1
    }
]

export { CertificateAcquiredSchema };

export const CourseSchema: SchemaDefinition = {
    name: "Course",
    plural: "Courses",
    display: "Corsi",
    displaySingular: "Corso",
    booleanEnabledGroups: [
        {
            field: "in_presence",
            value: true,
            fieldGroup: ["platform", "city", "postal_code", "street", "house_number"]
        }
    ],
    additionalInfo: {
        "id": {
            automatic: true
        },
        "proponent_id": {
            automatic: true
        },
        "creator_id": {
            automatic: true
        },
        "creation_date": {
            automatic: true
        },
        "duration": Numeric,
        "description": {
            inputMode: "text",
            optional: true
        },
        "platform": {
            optional: true
        },
        "city": {
            optional: true
        },
        "postal_code": {
            optional: true
        },
        "street": {
            optional: true
        },
        "house_number": {
            optional: true
        },
        "address": {
            automatic: true
        },
        "temporary": {
            automatic: true
        },
        "url": Url
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "creator_id",
            "headerName": "ID Creatore",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "proponent_id",
            "headerName": "ID Proponente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "name",
            "headerName": "Nome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "url",
            "headerName": "URL",
            "type": "string",
            "flex": 1
        },
        {
            "field": "description",
            "headerName": "Descrizione",
            "type": "string",
            "flex": 1
        },
        {
            "field": "creation_date",
            "headerName": "Data Creazione",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "duration",
            "headerName": "Durata (ore)",
            "type": "number",
            "flex": 1
        },
        {
            "field": "temporary",
            "headerName": "Temporaneo",
            "type": "boolean"
        },
        {
            "field": "in_presence",
            "headerName": "In Presenza",
            "type": "boolean"
        },
        {
            "field": "address",
            "headerName": "Indirizzo",
            "type": "string",
            "flex": 1,
            "sortable": false,
            "filterable": false,
            "valueGetter": (value, row) => StringUtils.getFormattedAddress(row.street, row.house_number, row.city, row.postal_code)
        },
        {
            "field": "city",
            "headerName": "Città",
            "type": "string",
            "flex": 1
        },
        {
            "field": "postal_code",
            "headerName": "CAP",
            "type": "string",
            "flex": 1
        },
        {
            "field": "street",
            "headerName": "Via",
            "type": "string",
            "flex": 1
        },
        {
            "field": "house_number",
            "headerName": "Numero Civico",
            "type": "string",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "creator_id", "proponent_id", "city", "street", "house_number", "postal_code"]
}

const ProposalSchema: SchemaDefinition = {
    name: "Proposal",
    plural: "Proposals",
    display: "Proposte",
    displaySingular: "Proposta",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "user_id": UserId,
        "proponent_id": {
            automatic: true
        },
        "course_id": {
            fieldLinkInfo: {
                schema: CourseSchema,
                shownFieldName: "name",
                getApiMethod: () => {
                    let api = ApiProvider.getApi();
                    return api.getNonTemporaryCourses;
                }
            }
        },
        "duration": Numeric,
        "note": {
            inputMode: "text",
            optional: true
        },
        "description": {
            inputMode: "text",
            optional: true
        },
        "manager_note": {
            inputMode: "text",
            automatic: true
        },
        "state": {
            automatic: true,
            defaultValue: "creation",
            possibilities: [
                { name: "Creata", value: "creation" },
                { name: "In Approvazione", value: "under_approval", color: "info" },
                { name: "Approvata", value: "approved", color: "success" },
                { name: "Rifiutata", value: "rejected", color: "error" }
            ]
        },
        "proposal_date": {
            automatic: true
        },
        "start_date": {
            dateConfiguration: {
                disablePast: true
            }
        },
        "address": {
            automatic: true
        },
        "proponent_name": {
            automatic: true
        },
        "url": Url,
        "postal_code": {
            optional: INITIAL_PERIOD
        },
        "street": {
            optional: INITIAL_PERIOD
        },
        "house_number": {
            optional: INITIAL_PERIOD
        }
    },
    requiredGroups: [
        [
            {
                groupName: "Corso Esistente",
                fieldNames: ["course_id"],
                isEnabledForEdit: (row: any) => {
                    return row.course?.temporary === false
                }
            },
            {
                groupName: "Nuovo Corso",
                fieldNames: ["name", "duration", "url", "description", "in_presence", "city", "postal_code", "street", "house_number"],
                isEnabledForEdit: (row: any) => {
                    return row.course?.temporary === true
                }
            }
        ]
    ],
    booleanEnabledGroups: [
        {
            field: "in_presence",
            value: true,
            fieldGroup: ["city", "postal_code", "street", "house_number"]
        }
    ],
    useUserColDefForFormIfUserIdIsPresent: true,
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "user_id",
            "headerName": "ID Utente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "proponent_id",
            "headerName": "ID Proponente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "course_id",
            "headerName": "ID Corso",
            "type": "string",
            "maxWidth": 100,
            "valueGetter": (value, row) => row.course?.id ?? row.course_id ?? ""
        },
        {
            "field": "proposal_date",
            "headerName": "Data",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "state",
            "headerName": "Stato",
            "type": "string",
            "minWidth": 150,
            "flex": 1,
            "sortable": false
        },
        {
            "field": "start_date",
            "headerName": "Data Inizio",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "note",
            "headerName": "Note",
            "type": "string",
            "flex": 1
        },
        {
            "field": "manager_note",
            "headerName": "Note Manager",
            "type": "string",
            "minWidth": 120,
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "user_id", "proponent_id", "course_id", "city", "postal_code", "house_number", "street"]
}

// Additional fields from ProposalPopulated
ProposalSchema.userColDef = [
    {
        "field": "id",
        "headerName": "ID",
        "type": "string",
        "sortable": false,
        "filterable": false,
        "maxWidth": 100
    },
    {
        "field": "course_id",
        "headerName": "ID Corso",
        "type": "string",
        "maxWidth": 100,
        "valueGetter": (value, row) => row.course?.id ?? ""
    },
    {
        "field": "proposal_date",
        "headerName": "Data",
        "type": "date",
        "valueGetter": DateGetter,
        "valueFormatter": DateFormatter,
        "flex": 1
    },
    {
        "field": "proponent_name",
        "headerName": "Proponente",
        "type": "string",
        "flex": 1,
        "sortable": false,
        "filterable": false,
    },
    {
        "field": "state",
        "headerName": "Stato",
        "type": "string",
        "minWidth": 150,
        "flex": 1
    },
    {
        "field": "start_date",
        "headerName": "Data Inizio",
        "type": "date",
        "valueGetter": DateGetter,
        "valueFormatter": DateFormatter,
        "flex": 1
    },
    {
        "field": "name",
        "headerName": "Nome",
        "type": "string",
        "flex": 1,
        "minWidth": 150,
        "valueGetter": (value, row) => row.course ? row.course.name : row.name
    },
    {
        "field": "duration",
        "headerName": "Durata (ore)",
        "type": "number",
        "flex": 1,
        "valueGetter": (value, row) => row.course ? row.course.duration : row.duration
    },
    {
        "field": "in_presence",
        "headerName": "In Presenza",
        "type": "boolean",
        "valueGetter": (value, row) => row.course ? row.course.in_presence : row.in_presence
    },
    {
        "field": "address",
        "headerName": "Indirizzo",
        "type": "string",
        "flex": 1,
        "sortable": false,
        "filterable": false,
        "valueGetter": (value, row) => row.course ? StringUtils.getFormattedAddress(row.course.street, row.course.house_number, row.course.city, row.course.postal_code) : StringUtils.getFormattedAddress(row.street, row.house_number, row.city, row.postal_code)
    },
    {
        "field": "city",
        "headerName": "Città",
        "type": "string",
        "flex": 1,
        "valueGetter": (value, row) => row.course ? row.course.city : row.city
    },
    {
        "field": "postal_code",
        "headerName": "CAP",
        "type": "string",
        "flex": 1,
        "valueGetter": (value, row) => row.course ? row.course.postal_code : row.postal_code
    },
    {
        "field": "street",
        "headerName": "Via",
        "type": "string",
        "flex": 1,
        "valueGetter": (value, row) => row.course ? row.course.street : row.street
    },
    {
        "field": "house_number",
        "headerName": "Num. Civico",
        "type": "string",
        "valueGetter": (value, row) => row.course ? row.course.house_number : row.house_number
    },
    {
        "field": "url",
        "headerName": "URL",
        "type": "string",
        "flex": 1,
        "valueGetter": (value, row) => row.course ? row.course.url : row.url
    },
    {
        "field": "note",
        "headerName": "Note",
        "type": "string",
        "flex": 1
    },
    {
        "field": "manager_note",
        "headerName": "Note Manager",
        "type": "string",
        "minWidth": 120,
        "flex": 1
    },
    {
        "field": "description",
        "headerName": "Descrizione",
        "type": "string",
        "valueGetter": (value, row) => row.course?.description,
        "flex": 1
    }
]

export { ProposalSchema };

const CourseAttendedSchema: SchemaDefinition = {
    name: "CourseAttended",
    plural: "CoursesAttended",
    display: "Corsi Frequentati",
    displaySingular: "Corso Frequentato",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "user_id": UserId,
        "proposal_id": {
            automatic: true
        },
        "with_proposal": {
            automatic: true,
            defaultValue: false
        },
        "course_id": {
            fieldLinkInfo: {
                schema: CourseSchema,
                shownFieldName: "name"
            }
        },
        "state": {
            automatic: true,
            possibilities: [
                { name: "Pianificato", value: "planned" },
                { name: "Iniziato", value: "started", color: "info" },
                { name: "Completato", value: "completed", color: "success" },
                { name: "Abbandonato", value: "discontinued", color: "error" }
            ]
        },
        "description": {
            inputMode: "text",
            optional: true
        },
        "discontinuance_date": {
            automatic: true
        },
        "completion_date": {
            optional: true,
            dateConfiguration: {
                disableFuture: true
            }
        },
        "start_date": {
            dateConfiguration: {
                disableFuture: true
            }
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "user_id",
            "headerName": "ID Utente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "state",
            "headerName": "Stato",
            "type": "string",
            "flex": 1,
            "sortable": false
        },
        {
            "field": "start_date",
            "headerName": "Data Inizio",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "completion_date",
            "headerName": "Data Completamento",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "discontinuance_date",
            "headerName": "Data Abbandono",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "with_proposal",
            "headerName": "Da Proposta",
            "type": "boolean",
            "flex": 1
        },
        {
            "field": "proposal_id",
            "headerName": "ID Proposta",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "course_id",
            "headerName": "ID Corso",
            "type": "string",
            "maxWidth": 100
        }
    ],
    hiddenColNames: ["id", "user_id", "proposal_id", "course_id"]
}

// Additional fields from CourseAttendedPopulated
CourseAttendedSchema.userColDef = [
    ...CourseAttendedSchema.colDef.filter(col => col.field !== "user_id"),
    {
        "field": "duration",
        "headerName": "Durata (ore)",
        "type": "number",
        "valueGetter": (value, row) => row.course ? row.course.duration : row.proposal.duration,
        "flex": 1
    },
    {
        "field": "proposal_date",
        "headerName": "Data Proposta",
        "type": "date",
        "valueGetter": (value, row) => row.proposal ? DateGetter(row.proposal.proposal_date, row) : "",
        "valueFormatter": DateFormatter,
        "flex": 1
    },
    {
        "field": "name",
        "headerName": "Nome",
        "type": "string",
        "valueGetter": (value, row) => row.course.name,
        "flex": 1
    },
    {
        "field": "description",
        "headerName": "Descrizione",
        "type": "string",
        "valueGetter": (value, row) => row.course.description,
        "flex": 1
    },
    {
        "field": "in_presence",
        "headerName": "In Presenza",
        "type": "boolean",
        "valueGetter": (value, row) => row.course.in_presence,
        "flex": 1
    }
]

export { CourseAttendedSchema };

export const NotificationSchema: SchemaDefinition = {
    name: "Notification",
    plural: "Notifications",
    display: "Notifiche",
    displaySingular: "Notifica",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "user_id": UserId,
        "notify_date": {
            automatic: true
        },
        "certificate_acquired_id": Uuid,
        "course_attended_id": Uuid,
        "proposal_id": Uuid
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "user_id",
            "headerName": "ID Utente",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "notify_date",
            "headerName": "Data Notifica",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "visualized",
            "headerName": "Visualizzata",
            "type": "boolean",
            "flex": 1
        },
        {
            "field": "message",
            "headerName": "Messaggio",
            "type": "string",
            "flex": 1
        },
        {
            "field": "certificate_acquired_id",
            "headerName": "ID Acquisizione Certificazione",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "course_attended_id",
            "headerName": "ID Corso Frequentato",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "proposal_id",
            "headerName": "ID Proposta",
            "type": "string",
            "maxWidth": 100
        }
    ],
    hiddenColNames: ["id", "user_id", "certificate_acquired_id", "course_attended_id", "proposal_id"]
}

export const SkillSchema: SchemaDefinition = {
    name: "Skill",
    plural: "Skills",
    display: "Skills",
    displaySingular: "Skill",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "creator_id": {
            automatic: true
        },
        "creation_date": {
            automatic: true
        },
        "description": {
            inputMode: "text",
            optional: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "creator_id",
            "headerName": "ID Creatore",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "name",
            "headerName": "Nome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "creation_date",
            "headerName": "Data Creazione",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "description",
            "headerName": "Descrizione",
            "type": "string",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "creator_id"]
}

const SkillAcquiredSchema: SchemaDefinition = {
    name: "SkillAcquired",
    plural: "SkillsAcquired",
    display: "Skill Acquisite",
    displaySingular: "Skill Acquisita",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "user_id": UserId,
        "skill_id": {
            fieldLinkInfo: {
                schema: SkillSchema,
                shownFieldName: "name"
            }
        },
        "acquiry_date": {
            automatic: true
        },
        "last_modification_date": {
            automatic: true
        },
        "level": Numeric,
        "description": {
            inputMode: "text",
            optional: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "user_id",
            "headerName": "ID Utente",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "skill_id",
            "headerName": "ID Skill",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "acquiry_date",
            "headerName": "Data Acquisizione",
            "type": "string",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "last_modification_date",
            "headerName": "Data Ultima Modifica",
            "type": "string",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "level",
            "headerName": "Livello",
            "type": "number",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "user_id", "skill_id", "acquiry_date"]
}

// Additional fields from SkillAcquiredPopulated
SkillAcquiredSchema.userColDef = [
    ...SkillAcquiredSchema.colDef.filter(col => col.field !== "user_id"),
    {
        "field": "name",
        "headerName": "Nome",
        "type": "string",
        "valueGetter": (value, row) => row.skill.name,
        "flex": 1
    },
    {
        "field": "description",
        "headerName": "Descrizione",
        "type": "string",
        "valueGetter": (value, row) => row.skill.description,
        "flex": 1
    },
    {
        "field": "categories",
        "headerName": "Categorie",
        "type": "string",
        "valueGetter": (value, row) => row.categories.join(", "),
        "flex": 1
    }
]

export { SkillAcquiredSchema };

export const SkillsCategorySchema: SchemaDefinition = {
    name: "SkillsCategory",
    plural: "SkillsCategories",
    display: "Categorie Skills",
    displaySingular: "Categoria Skills",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "creator_id": {
            automatic: true
        },
        "creation_date": {
            automatic: true
        },
        "description": {
            inputMode: "text",
            optional: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "creator_id",
            "headerName": "ID Creatore",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "name",
            "headerName": "Nome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "creation_date",
            "headerName": "Data Creazione",
            "type": "date",
            "valueGetter": DateGetter,
            "valueFormatter": DateFormatter,
            "flex": 1
        },
        {
            "field": "description",
            "headerName": "Descrizione",
            "type": "string",
            "flex": 1
        }
    ],
    hiddenColNames: ["id", "creator_id"]
}

export const UserSchema: SchemaDefinition = {
    name: "User",
    plural: "Users",
    display: "Utenti",
    displaySingular: "Utente",
    additionalInfo: {
        "id": {
            automatic: true
        },
        "manager_id": {
            ...Uuid,
            optional: true
        },
        "registration_number": {
            optional: true
        },
        "team_name": {
            optional: true
        },
        "roles": {
            automatic: true
        },
        "manager_name": {
            automatic: true
        }
    },
    colDef: [
        {
            "field": "id",
            "headerName": "ID",
            "type": "string",
            "sortable": false,
            "filterable": false,
            "maxWidth": 100
        },
        {
            "field": "name",
            "headerName": "Nome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "surname",
            "headerName": "Cognome",
            "type": "string",
            "flex": 1
        },
        {
            "field": "email",
            "headerName": "Email",
            "type": "string",
            "flex": 1
        },
        {
            "field": "registration_number",
            "headerName": "Matricola",
            "type": "string",
            "flex": 1
        },
        {
            "field": "manager_id",
            "headerName": "ID Manager",
            "type": "string",
            "maxWidth": 100
        },
        {
            "field": "manager_name",
            "headerName": "Nome Manager",
            "type": "string",
            "flex": 1,
            "valueGetter": (value, row) => row.manager_info ? `${row.manager_info.name} ${row.manager_info.surname}` : ""
        },
        {
            "field": "team_name",
            "headerName": "Nome Team",
            "type": "string",
            "flex": 1
        },
        {
            "field": "roles",
            "headerName": "Ruoli",
            "type": "string",
            "valueGetter": UserRoleStringGetter
        },
        {
            "field": "team_member",
            "headerName": "Team Member",
            "type": "boolean"
        },
        {
            "field": "manager",
            "headerName": "Manager",
            "type": "boolean"
        },
        {
            "field": "hr",
            "headerName": "HR",
            "type": "boolean"
        },
        {
            "field": "data_manager",
            "headerName": "Data Manager",
            "type": "boolean"
        },
        {
            "field": "skills_user",
            "headerName": "Skills User",
            "type": "boolean"
        }
    ],
    hiddenColNames: ["id", "manager_id", "team_member", "manager", "hr", "data_manager", "skills_user"]
}
