import "./AddItemModal.scss";
import { Autocomplete, TextField, FormControlLabel, Checkbox, Button, Box, RadioGroup, Radio } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { AuthContextProps } from "oidc-react";
import { useState, createRef, SyntheticEvent, FormEvent, useEffect, useLayoutEffect, useContext } from "react";
import ApiAdapter from "../../../api/ApiAdapter";
import SchemaUtils from "../../../utils/SchemaUtils";
import UserDataUtils from "../../../utils/UserDataUtils";
import { FieldLinkInfo, SchemaDefinition } from "../../../utils/schemas";
import { SelectOption } from "../../../utils/types";
import ModalBase from "../ModalBase/ModalBase";
import LocationUtils from "../../../utils/LocationUtils";
import { useLocation } from "react-router-dom";
import { SnackNotificationContext } from "../../../app/App";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import ApiProvider from "../../../utils/ApiProvider";

export type ModalMode = {
    name: "add" | "edit",
    successMessage: string,
    errorMessage: string,
    modalTitleVerb: string,
    defaultValuesRequired: boolean
}

export const AddModalMode: ModalMode = {
    name: "add",
    successMessage: "Elemento aggiunto!",
    errorMessage: "Impossibile completare l'operazione.",
    modalTitleVerb: "Aggiungi",
    defaultValuesRequired: false
}

export const EditModalMode: ModalMode = {
    name: "edit",
    successMessage: "Elemento modificato!",
    errorMessage: "Impossibile completare l'operazione.",
    modalTitleVerb: "Modifica",
    defaultValuesRequired: true
}

export default function AddItemModal({
    open,
    parentOpenFunction,
    parentCloseFunction,
    schema,
    auth,
    api,
    table,
    userId,
    onSuccessFunction,
    getEditDisabledFields,
    defaultValues,
    mode,
    apiRef
}: {
    open: boolean,
    parentOpenFunction: () => void,
    parentCloseFunction: () => void,
    schema: SchemaDefinition,
    auth: AuthContextProps,
    api: ApiAdapter,
    table: string,
    userId?: string,
    onSuccessFunction: () => void,
    getEditDisabledFields?: (params: any) => string[],
    defaultValues?: {[key: string]: any},
    mode: ModalMode,
    apiRef: React.MutableRefObject<GridApiCommunity>
}) {
    const [ invalidFields, setInvalidFields ] = useState<{ name: string, errorMessage: string }[]>([]);
    const [ autocompleteFieldsOptionsMap, setAutocompleteFieldsOptionsMap ] = useState<{[key: string]: SelectOption[]}>({});
    const [ autocompleteFieldsSelectedOptionMap, setAutocompleteFieldsSelectedOptionMap ] = useState<{[key: string]: SelectOption | undefined}>({});
    const [ autocompleteFieldsLoadingMap, setAutocompleteFieldsLoadingMap ] = useState<{[key: string]: boolean}>({});
    const [ defaultEnumValues, setDefaultEnumValues ] = useState<{[key: string]: SelectOption}>({});
    const [ fieldGroupSetMapping, setFieldGroupSetMapping ] = useState<{[key: string]: string}>({});
    const [ booleanDisabledFieldNames, setBooleanDisabledFieldNames ] = useState<string[]>([]);
    const [ processingRequest, setProcessingRequest ] = useState(false);
    const snackNotificationContext = useContext(SnackNotificationContext);
    const addItemFormRef = createRef<HTMLFormElement>();
    const location = useLocation();

    const formattedDefaultValues: {[key: string]: any} = {};
    const editDisabledFields: string[] = [];
    if (defaultValues && mode.name === EditModalMode.name) {
        if (getEditDisabledFields) {
            getEditDisabledFields(defaultValues).forEach(value => editDisabledFields.push(value));
        }
        getNonAutomaticFields().forEach(def => {
            let formattedValue = apiRef.current.getRowFormattedValue(defaultValues, def);
            if (formattedValue !== "" && formattedValue !== undefined && formattedValue !== null) {
                if (def.type === "date" || def.type === "dateTime") {
                    formattedDefaultValues[def.field] = dayjs(defaultValues[def.field]);
                } else {
                    formattedDefaultValues[def.field] = formattedValue;
                }
            }
        });
        if (defaultValues.id) {
            formattedDefaultValues.id = defaultValues.id;
        }
    }

    const searchDebounceTimeMs = 500;
    let debounceTimeoutId: number = -1;

    // Initialize the defaultValues for enums as the first element.
    // This also takes care of setting those as the current selected values
    // of the Enum Autocomplete fields.
    useEffect(() => {
        const newDefaultEnumValues: {[key: string]: SelectOption} = {};
        getEnumFields().forEach(col => {
            let fieldName = col.field;
            let possibilities = SchemaUtils.getEnumPossibilities(schema, fieldName);
            // If a default value was passed, find the corresponding SelectOptions from the possibilities array.
            if (defaultValues && formattedDefaultValues[fieldName]) {
                let defaultPossibility = possibilities.find(poss => poss.value === formattedDefaultValues[fieldName]);
                if (defaultPossibility) {
                    newDefaultEnumValues[fieldName] = defaultPossibility;
                    return;
                }
            }
            newDefaultEnumValues[fieldName] = possibilities[0];
        });
        setDefaultEnumValues(newDefaultEnumValues);
        setAutocompleteFieldsSelectedOptionMap(oldMap => {
            let newMap = { ...oldMap };
            Object.keys(newDefaultEnumValues).forEach(fieldName => {
                newMap[fieldName] = newDefaultEnumValues[fieldName];
            })
            return newMap;
        });
        // Initialize grouped fields to select the first group available
        if (schema.requiredGroups) {
            let initialMap = {} as {[key: string]: string};
            schema.requiredGroups.forEach(fieldSet => {
                let key = getFieldSetKey(fieldSet);
                initialMap[key] = fieldSet[0].groupName;
            });
            setFieldGroupSetMapping(initialMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);

    // Initialize boolean-disabled fields on modal open and group change
    useLayoutEffect(() => {
        if (open) {
            let initialBooleanDisabledFields = [] as string[];
            if (schema.booleanEnabledGroups) {
                schema.booleanEnabledGroups.forEach(group => {
                    if (formattedDefaultValues[group.field] !== group.value) {
                        group.fieldGroup.forEach(fieldName => {
                            if (!initialBooleanDisabledFields.includes(fieldName)) {
                                initialBooleanDisabledFields.push(fieldName);
                            }
                        })
                    }
                })
            }
            setBooleanDisabledFieldNames(initialBooleanDisabledFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, fieldGroupSetMapping])

    function getColDef(): GridColDef[] {
        if (schema.useUserColDefForFormIfUserIdIsPresent && userId && schema.userColDef) {
            return schema.userColDef;
        }
        return schema.colDef;
    }

    function closeAddItemModal() {
        parentCloseFunction();
        setInvalidFields([]);
        setBooleanDisabledFieldNames([]);
    }

    /**
     * Checks if the field's name is present in the invalidFields array.
     */
    function isInvalidField(fieldName: string): boolean {
        return invalidFields.map(fieldObject => fieldObject.name).includes(fieldName);
    }

    /**
     * Get the errorMessage property of a field with a validation error.
     */
    function getInvalidFieldErrorMessage(fieldName: string): string {
        if (!isInvalidField(fieldName)) {
            return " ";
        }

        return invalidFields.find(fieldObject => fieldObject.name === fieldName)!.errorMessage;
    }

    /**
     * Retrieves the input mode of the given field from the table schema.
     */
    function getInputMode(fieldName: string): "search" | "text" | "none" | "email" | "tel" | "url" | "numeric" | "decimal" | undefined {
        if (!schema.additionalInfo) {
            return undefined;
        }
        // Not undefined
        let finalAdditionalInfo = schema.additionalInfo;
        
        if (!finalAdditionalInfo[fieldName]) {
            return undefined;
        }
    
        return finalAdditionalInfo[fieldName].inputMode;
    }
    
    /**
     * Returns whether the given field is optional
     * (doesn't have to be filled in the form).
     */
    function isOptional(fieldName: string): boolean {
        if (!schema.additionalInfo) {
            return false;
        }
        // Not undefined
        let finalAdditionalInfo = schema.additionalInfo;
    
        if (!finalAdditionalInfo[fieldName]) {
            return false;
        }
    
        if (!finalAdditionalInfo[fieldName].optional) {
            return false;
        }
    
        return true;
    }

    function getAutomaticFields(): GridColDef[] {
        if (!schema.additionalInfo) {
            return [] as GridColDef[];
        }

        const additionalInfo = schema.additionalInfo;
        const automaticFieldNames = Object.keys(additionalInfo).filter(info => additionalInfo[info].automatic === true);
        const result = [] as GridColDef[];
        automaticFieldNames.forEach(fieldName => {
            let def = getColDef().find(def => def.field === fieldName);
            if (def) {
                result.push(def);
            }
        });
        return result;
    }

    function getNonAutomaticFields(): GridColDef[] {
        let automaticFieldsNames = getAutomaticFields().map(def => def.field);

        // Allow specifying a user_id if opened in the database page.
        // This opens up the ability to add rows for other users.
        if (LocationUtils.isDatabasePage(location.pathname)) {
            automaticFieldsNames = automaticFieldsNames.filter(fieldName => fieldName !== "user_id");
        }

        return getColDef().filter(def => !automaticFieldsNames.includes(def.field));
    }

    /**
     * Returns an array containing the column definitions of all the fields that
     * should be presented as an Autocomplete field where the user can search for data
     * from another table schema.
     */
    function getLinkedFields(): GridColDef[] {
        if (!schema.additionalInfo) {
            return [] as GridColDef[];
        }

        const additionalInfo = schema.additionalInfo;
        const linkedFieldNames = Object.keys(additionalInfo).filter(info => additionalInfo[info].fieldLinkInfo !== undefined);
        const result = [] as GridColDef[];
        linkedFieldNames.forEach(fieldName => {
            let def = getColDef().find(def => def.field === fieldName);
            if (def) {
                result.push(def);
            }
        });
        return result;
    }

    function getEnumFields(): GridColDef[] {
        if (!schema.additionalInfo) {
            return [] as GridColDef[];
        }

        const additionalInfo = schema.additionalInfo;
        const textAreaFieldNames = Object.keys(additionalInfo).filter(info => additionalInfo[info].possibilities !== undefined);
        const result = [] as GridColDef[];
        textAreaFieldNames.forEach(fieldName => {
            let def = getColDef().find(def => def.field === fieldName);
            if (def) {
                result.push(def);
            }
        });
        return result;
    }
    /**
     * Returns an array containing the column definitions of all the fields that
     * should be presented as a Checkbox with a label beside it.
     */
    function getBooleanFields(): GridColDef[] {
        return getNonAutomaticFields().filter(def => def.type === "boolean");
    }

    function getGroupedFieldNames(): string[] {
        if (!schema.requiredGroups) {
            return [];
        }

        let groupObjects = schema.requiredGroups.flat(2);
        let result = [] as string[];
        groupObjects.forEach(group => result.push(...group.fieldNames));
        return result;
    }

    function getNonGroupedFieldNames(): string[] {
        let nonAutomaticFieldsNames = getNonAutomaticFields().map(def => def.field);
        if (!schema.requiredGroups) {
            return nonAutomaticFieldsNames;
        }

        let groupedFieldsNames = getGroupedFieldNames();
        return nonAutomaticFieldsNames.filter(fieldName => !groupedFieldsNames.includes(fieldName));
    }

    function getFieldNamesInRenderOrder(): string[] {
        let renderOrder: string[];
        if (schema.renderOrder) {
            renderOrder = schema.renderOrder;
        } else {
            renderOrder = getNonGroupedFieldNames();
        }

        // Remove linked fields from the list, if they should not be shown.
        if (!shouldShowLinkedFields()) {
            const linkedFieldsNames = getLinkedFields().map(def => def.field);
            renderOrder = renderOrder.filter(fieldName => !linkedFieldsNames.includes(fieldName));
        }

        return renderOrder;
    }

    function toggleButtonClicked(event: React.ChangeEvent<HTMLInputElement>, value: any, fieldSet: { groupName: string, fieldNames: string[] }[]) {
        if (value === null) {
            return;
        }

        // Clear autocomplete options and selected options from current mapping.
        let oldGroupName = fieldGroupSetMapping[getFieldSetKey(fieldSet)];
        let oldGroupFieldNames = fieldSet.find(set => set.groupName === oldGroupName)!.fieldNames;
        let oldAutocompleteFieldNames = oldGroupFieldNames.filter(fieldName => schema.additionalInfo?.[fieldName]?.fieldLinkInfo !== undefined);
        
        setAutocompleteFieldsOptionsMap(oldMap => {
            let newMap = { ...oldMap };
            oldAutocompleteFieldNames.forEach(fieldName => {
                delete newMap[fieldName];
            });
            return newMap;
        });

        setAutocompleteFieldsSelectedOptionMap(oldMap => {
            let newMap = { ...oldMap };
            oldAutocompleteFieldNames.forEach(fieldName => {
                delete newMap[fieldName];
            });
            return newMap;
        })

        setFieldGroupSetMapping(oldMapping => {
            let newMapping = { ...oldMapping };
            newMapping[getFieldSetKey(fieldSet)] = value;
            return newMapping;
        });
    }

    function getFieldSetKey(fieldSet: { groupName: string, fieldNames: string[] }[]): string {
        return fieldSet.map(set => set.groupName).join("+");
    }

    function getRenderedFieldGroups(): JSX.Element {
        if (!schema.requiredGroups) {
            return <></>;
        }

        const requiredGroups = schema.requiredGroups;

        switch (mode.name) {
            case "add": {
                // Show all the groups of each fieldSet.
                return <>{
                    requiredGroups.map(fieldSet => {
                        const fieldSetKey = getFieldSetKey(fieldSet);
                        return <Box key={`${table}-fieldSet-${fieldSetKey}-container`} sx={{ padding: "8px", margin: "8px", border: (theme) => `1px solid ${theme.palette.divider}`, position: "relative" }}>
                            <RadioGroup row key={`${table}-fieldSet-${fieldSetKey}-buttonGroup`} name="" sx={{ paddingBottom: "8px", paddingInline: "4px", gap: "16px", borderBottom: (theme) => `1px solid ${theme.palette.divider}`, width: "100%" }}
                                onChange={(event, value) => toggleButtonClicked(event, value, fieldSet)} value={fieldGroupSetMapping[fieldSetKey] ?? fieldSet.flat()[0].groupName}>
                                
                                {
                                    fieldSet.flat().map((group, i) =>
                                        <FormControlLabel value={group.groupName} key={`${table}-fieldSet-${fieldSetKey}-button-${i}`}
                                            control={<Radio />} label={group.groupName} />
                                    )
                                }
                            </RadioGroup>
                            {
                                fieldSet.map(group => 
                                    <div key={`${table}-fieldSet-${fieldSetKey}-group-${group.groupName}-container`}>
                                        { fieldGroupSetMapping?.[fieldSetKey] === group.groupName &&
                                            <Box key={`${table}-fieldSet-${fieldSetKey}-group-${group.groupName}`}
                                                sx={{ padding: 2, display: "grid", gridTemplateColumns: "1fr 1fr 1fr", alignItems: "center", justifyContent: "stretch" }}>
                                                {
                                                    group.fieldNames.map(fieldName => renderFieldByName(fieldName))
                                                }
                                            </Box>
                                        }
                                    </div>
                                )
                            }
                        </Box>
                    })
                }</>
            }
            case "edit": {
                // For each fieldSet, only show fields from the first group whose edit predicate returns true.
                return <>{
                    requiredGroups.map(fieldSet => {
                        const fieldSetKey = getFieldSetKey(fieldSet);
                        const editableGroup = fieldSet.find(fieldGroup => {
                            return fieldGroup.isEnabledForEdit(defaultValues);
                        });

                        if (!editableGroup) {
                            return undefined;
                        }

                        return <Box key={`${table}-fieldSet-${fieldSetKey}-container`} sx={{ padding: "8px", margin: "8px", border: (theme) => `1px solid ${theme.palette.divider}`, position: "relative" }}>
                            <RadioGroup row key={`${table}-fieldSet-${fieldSetKey}-buttonGroup`} name="" sx={{ paddingBottom: "8px", paddingInline: "4px", gap: "16px", borderBottom: (theme) => `1px solid ${theme.palette.divider}`, width: "100%" }}
                                onChange={(event, value) => toggleButtonClicked(event, value, fieldSet)} value={editableGroup.groupName}>
                                {
                                    fieldSet.flat().map((group, i) =>
                                        <FormControlLabel value={group.groupName} key={`${table}-fieldSet-${fieldSetKey}-button-${i}`}
                                            control={<Radio disabled />} label={group.groupName} />
                                    )
                                }
                            </RadioGroup>
                            {
                                <div key={`${table}-fieldSet-${fieldSetKey}-group-${editableGroup.groupName}-container`}>
                                    <Box key={`${table}-fieldSet-${fieldSetKey}-group-${editableGroup.groupName}`}
                                        sx={{ padding: 2, display: "grid", gridTemplateColumns: "1fr 1fr 1fr", alignItems: "center", justifyContent: "stretch" }}>
                                        {
                                            editableGroup.fieldNames.map(fieldName => renderFieldByName(fieldName))
                                        }
                                    </Box>
                                </div>
                            }
                        </Box>
                    })
                }</>


            }
        }

        
    }

    /**
     * Clears the array of selectable options from the given field's Autocomplete.
     */
    function clearAutocompleteOptions(fieldName: string) {
        setAutocompleteFieldsOptionsMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = [];
            return newMap;
        });
    }

    /**
     * Returns an object containing information about which other schema and field
     * the given field is linked to.
     */
    function getLinkedSchemaInfo(fieldName: string): FieldLinkInfo | undefined {
        // additionalInfo must be defined
        if (!schema.additionalInfo) {
            return;
        }
        // additionalInfo must contain fieldName as a key
        if (!schema.additionalInfo[fieldName]) {
            return;
        }
        let fieldAdditionalInfo = schema.additionalInfo[fieldName];
        // fieldLinkInfo must be defined
        if (!fieldAdditionalInfo.fieldLinkInfo) {
            return;
        }

        return fieldAdditionalInfo.fieldLinkInfo;
    }

    /**
     * Returns the header name of the field that the given field is linked to.
     */
    function getLinkedFieldHeaderName(fieldName: string): string {
        let linkedInfo = getLinkedSchemaInfo(fieldName);
        if (!linkedInfo) {
            return "";
        }
        // Not undefined
        const finalLinkedInfo = linkedInfo;

        let colDef = linkedInfo.schema.colDef.find(def => def.field === finalLinkedInfo.shownFieldName);
        if (!colDef) {
            return "";
        }
        return colDef.headerName ?? "";
    }

    function handleEnumSelect(event: SyntheticEvent<Element, Event>, value: string | null, fieldName: string) {
        if (value === null || value === "") {
            return;
        }

        setAutocompleteFieldsSelectedOptionMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = SchemaUtils.getEnumPossibilities(schema, fieldName).find(option => option.name === value);
            return newMap;
        });
    }

    function handleAutocompleteClose(fieldName: string) {
        // If the user closes the field, only clear the options if they have selected something.
        if (autocompleteFieldsSelectedOptionMap[fieldName]) {
            clearAutocompleteOptions(fieldName);
        }
    }

    /**
     * Stores the selected value in autocompleteFieldsOptionsMap under the key corresponding to the field's name.
     * The SelectObject to store is found by comparing the selected value to the list of selectable options.
     */
    function handleAutocompleteSelect(event: SyntheticEvent<Element, Event>, value: string | null, fieldName: string) {
        if (value === null || value === "") {
            setAutocompleteFieldsSelectedOptionMap(oldMap => {
                let newMap = { ...oldMap };
                delete newMap[fieldName];
                return newMap;
            });
            
            return;
        }

        setAutocompleteFieldsSelectedOptionMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = autocompleteFieldsOptionsMap[fieldName].find(option => option.name === value);
            return newMap;
        });
    }

    /**
     * Prevents from sending a request if this function is called within searchDebounceTimeMs of a
     * previous call.
     */
    function handleAutocompleteInputChangeWithDebounce(event: SyntheticEvent<Element, Event>, searchTerm: string, fieldName: string) {
        if (debounceTimeoutId === -1) {
            debounceTimeoutId = setTimeout(() => handleAutocompleteInputChange(event, searchTerm, fieldName), searchDebounceTimeMs) as unknown as number;
        } else {
            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(() => handleAutocompleteInputChange(event, searchTerm, fieldName), searchDebounceTimeMs) as unknown as number;
        }
    }

    /**
     * Makes a call to the API to request data with the user's search term.
     */
    async function handleAutocompleteInputChange(event: SyntheticEvent<Element, Event>, searchTerm: string, fieldName: string) {
        clearAutocompleteOptions(fieldName);

        if (!auth.userData || searchTerm === "") {
            return;
        }

        const linkInfo = getLinkedSchemaInfo(fieldName);

        if (linkInfo === undefined) {
            return;
        }

        setAutocompleteFieldsLoadingMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = true;
            return newMap;
        });

        let foundRows;
        if (linkInfo.getApiMethod) {
            const preferredApiMethod = linkInfo.getApiMethod();
            foundRows = await preferredApiMethod.apply(ApiProvider.getApi(), [linkInfo.schema.name, auth.userData.access_token, 10, 0,
                [{ field: linkInfo.shownFieldName, operator: "contains_case_insensitive", value: searchTerm }], []]);
        } else {
            foundRows = await api.getData(linkInfo.schema.name, auth.userData.access_token, 10, 0,
                [{ field: linkInfo.shownFieldName, operator: "contains_case_insensitive", value: searchTerm }], []);
        }
        const result: SelectOption[] = foundRows.map(row => {
            return { name: row.name, value: row.id };
        });

        setAutocompleteFieldsOptionsMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = result;
            return newMap;
        });
        
        setAutocompleteFieldsLoadingMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = false;
            return newMap;
        });
    }

    /**
     * Makes a static call to give the user some example options.
     * The call always uses limit=10 and sort=["name:asc"]
     */
    async function handleFocus(event: SyntheticEvent<Element, Event>, fieldName: string) {
        if (!auth.userData) {
            return;
        }
        
        // Return if something is already selected, in this field
        if (autocompleteFieldsSelectedOptionMap[fieldName]) {
            return;
        }

        const linkInfo = getLinkedSchemaInfo(fieldName);

        if (linkInfo === undefined) {
            return;
        }

        setAutocompleteFieldsLoadingMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = true;
            return newMap;
        });

        // Get example options
        let foundRows = await api.getData(linkInfo.schema.name, auth.userData.access_token, 10, 0, [], [{ field: "name", sort: "asc" }]);

        const result: SelectOption[] = foundRows.map(row => {
            return { name: row.name, value: row.id };
        });

        setAutocompleteFieldsOptionsMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = result;
            return newMap;
        });

        setAutocompleteFieldsLoadingMap(oldMap => {
            let newMap = { ...oldMap };
            newMap[fieldName] = false;
            return newMap;
        });
    }

    async function addItem(event: FormEvent) {
        if (event.type === "submit") {
            event.preventDefault();
        }

        if (!addItemFormRef.current || !auth.userData) {
            return;
        }

        const formData = new FormData(addItemFormRef.current);

        // Validation
        const localInvalidFields: { name: string, errorMessage: string }[] = [];
        formData.forEach((value, key) => {
            let field = getColDef().find(def => def.field === key);
            if (!schema.additionalInfo || !schema.additionalInfo[key]) {
                // Empty date fields render as '""' anyway. They should only be disallowed if the field
                // is set to be required, and allowed otherwise.
                if (value.toString() === "" && field?.type !== "date" && field?.type !== "dateTime") {
                    localInvalidFields.push({ name: key, errorMessage: "Il campo è obbligatorio" });
                }
                return;
            }

            // Additional info is present
            let fieldInfo = schema.additionalInfo[key];
            if (value.toString() === "") {
                if (!fieldInfo.optional) {
                    localInvalidFields.push({ name: key, errorMessage: "Il campo è obbligatorio" });
                }
                return;
            }

            if (fieldInfo.inputMode === "url") {
                try {
                    let urlValue = value.toString();
                    if (!urlValue.startsWith("https://")) {
                        urlValue = `https://${urlValue}`;
                    }
                    new URL(urlValue);
                    formData.set(key, urlValue);
                } catch (_) {
                    localInvalidFields.push({ name: key, errorMessage: "L'URL inserito non è corretto" });
                    return;
                }
            }

            if (fieldInfo.validationPattern) {
                const regex = new RegExp(fieldInfo.validationPattern);
                if (!value.toString().match(regex)) {
                    localInvalidFields.push({ name: key, errorMessage: "Il valore inserito non è corretto" });
                }
            }
        });
        
        if (localInvalidFields.length > 0) {
            setInvalidFields(localInvalidFields);
            return;
        }

        setInvalidFields([]);
        setProcessingRequest(true);

        // Substitute autocompleted values with their IDs
        Object.keys(autocompleteFieldsSelectedOptionMap).forEach(fieldName => {
            let selectOption = autocompleteFieldsSelectedOptionMap[fieldName];
            if (!selectOption) {
                return;
            }

            formData.set(fieldName, selectOption.value);
        });

        // Remove values corresponding to non-filled optional fields.
        let keysToDelete: string[] = [];
        formData.forEach((value, key) => {
            if (value === "" || value === undefined) {
                keysToDelete.push(key);
            }
        });
        keysToDelete.forEach(key => formData.delete(key));

        // Populate automatic fields which have a specified defaultValue
        if (schema.additionalInfo) {
            const additionalInfo = schema.additionalInfo;
            Object.keys(schema.additionalInfo).forEach(info => {
                if (additionalInfo[info].automatic === true && additionalInfo[info].defaultValue !== undefined) {
                    formData.set(info, additionalInfo[info].defaultValue as string);
                }
            });
        }

        // Add user_id if necessary and not on the database page.
        // If a userId is passed, use that one, otherwise get the one of the current user.
        // This allows editing other people's items while having them retain ownership of those items.
        if (SchemaUtils.colDefHasField(schema.colDef, "user_id") && !LocationUtils.isDatabasePage(location.pathname)) {
            if (userId) {
                formData.set("user_id", userId);
            } else {
                formData.set("user_id", UserDataUtils.getUserId(auth.userData));
            }
        }
        
        // Fix checkbox values: if it's not checked, formData won't even have a key for that checkbox.
        // If it is checked, it will have a key set to "on".
        let checkboxFieldNames = getBooleanFields().map(def => def.field);
        // Filter out checkbox fields that are in requiredGroups but not in a group that is currently active (selected).
        if (schema.requiredGroups) {
            Object.keys(fieldGroupSetMapping).forEach(fieldSetKey => {
                let fieldSetDefinition = schema.requiredGroups!.find(fieldSet => getFieldSetKey(fieldSet) === fieldSetKey);
                if (fieldSetDefinition) {
                    let activeFieldGroupName: string;
                    switch (mode.name) {
                        case "add": {
                            activeFieldGroupName = fieldGroupSetMapping[fieldSetKey];
                            break;
                        }
                        case "edit": {
                            const editableGroup = fieldSetDefinition.find(fieldGroup => {
                                return fieldGroup.isEnabledForEdit(defaultValues);
                            });
                            
                            activeFieldGroupName = editableGroup?.groupName ?? fieldGroupSetMapping[fieldSetKey]
                            break;
                        }
                    }
                    let allFieldSetFieldNames = fieldSetDefinition.map(fieldGroup => fieldGroup.fieldNames).flat();
                    let activeGroupFieldNames = fieldSetDefinition.find(set => set.groupName === activeFieldGroupName)!.fieldNames;
                    checkboxFieldNames = checkboxFieldNames.filter(fieldName => {
                        if (allFieldSetFieldNames.includes(fieldName) && !activeGroupFieldNames.includes(fieldName)) {
                            return false;
                        }
                        return true;
                    });
                }
            })
        }
        // Filter out checkbox fields that are also disabled in booleanDisabledFieldNames
        checkboxFieldNames = checkboxFieldNames.filter(name => !booleanDisabledFieldNames.includes(name));
        
        checkboxFieldNames.forEach(name => {
            if (formData.has(name)) {
                formData.set(name, "true");
                return;
            }
            formData.set(name, "false");
        });

        const dataObject = getDataObject(formData);
        let success = false;

        switch (mode.name) {
            case "add": {
                success = await api.addDataRow(table, auth.userData.access_token, dataObject);
                break;
            }
            case "edit": {
                if (shouldUsePrivilegedEditRoute()) {
                    success = await api.patchDataRow(table, auth.userData.access_token, formattedDefaultValues.id, dataObject);
                } else {
                    success = await api.modifyDataRow(table, auth.userData.access_token, formattedDefaultValues.id, dataObject);
                }

                break;
            }
        }

        if (success) {
            snackNotificationContext.displaySnackbarMessage("success", mode.successMessage);
            onSuccessFunction();
        } else {
            snackNotificationContext.displaySnackbarMessage("error", mode.errorMessage);
        }

        setAutocompleteFieldsSelectedOptionMap({});
        setAutocompleteFieldsOptionsMap({});
        setProcessingRequest(false);
        closeAddItemModal();
    }

    function getDataObject(formData: FormData): {[key: string]: string} {
        const dataObject: {[key: string]: any} = {};
        formData.forEach((value, key) => {
            let stringValue = value.toString();
            let colDefField = getColDef().find(def => def.field === key);
            if (colDefField === undefined) {
                dataObject[key] = stringValue;
                return;
            }
            
            // Convert dates to ISO format.
            if (colDefField.type === "date") {
                dataObject[key] = new Date(stringValue.split("/").reverse().join("/")).toISOString();
                return;
            }

            // Convert numbers to Number.
            if (colDefField.type === "number") {
                dataObject[key] = Number(stringValue);
                return;
            }

            // Convert booleans to Boolean.
            if (colDefField.type === "boolean") {
                if (stringValue === "false") {
                    dataObject[key] = false;
                    return;
                }
                dataObject[key] = true;
                return;
            }

            dataObject[key] = stringValue;
        });
        return dataObject;
    }

    function getSimpleDefaultValue(fieldName: string): any | undefined {
        return formattedDefaultValues[fieldName];
    }

    function getCheckboxDefaultChecked(fieldName: string): boolean {
        if (!formattedDefaultValues[fieldName]) {
            return false;
        }

        return formattedDefaultValues[fieldName];
    }

    function shouldShowLinkedFields(): boolean {
        return mode.name === "add";
    }

    function shouldUsePrivilegedEditRoute(): boolean {
        return LocationUtils.isDatabasePage(location.pathname) || LocationUtils.isResourcesPage(location.pathname);
    }

    /// Field Rendering ///

    function renderLinkedField(def: GridColDef): JSX.Element {
        if (!shouldShowLinkedFields()) {
            return <div key={`${def.field}-field-wrapper-disabled`}>
                { !booleanDisabledFieldNames.includes(def.field) &&
                    <TextField key={`${def.field}-autocomplete-disabled`} disabled={true}
                        label={`${def.headerName ? def.headerName.replace(/[iI][dD] /g, "") : ""} (non modificabile)`}
                        // Try to pull the value of the linked field name from the defaults, otherwise empty string is fine
                        value={getLinkedSchemaInfo(def.field)?.shownFieldName ? formattedDefaultValues[getLinkedSchemaInfo(def.field)?.shownFieldName!] ?? "" : ""}
                        sx={{ width: "100%" }} />
                }
            </div>
        }

        return <div key={`${def.field}-field-wrapper`}>
            { !booleanDisabledFieldNames.includes(def.field) &&
                <Autocomplete
                    key={`${def.field}-autocomplete`}
                    filterOptions={x => x}
                    options={autocompleteFieldsOptionsMap[def.field]?.map(option => option.name) ?? []}
                    renderInput={params => {
                            return <div className="fieldContainer">
                                    <TextField error={isInvalidField(def.field)} helperText={getInvalidFieldErrorMessage(def.field)} placeholder={`Ricerca per ${getLinkedFieldHeaderName(def.field)}`} {...params}
                                        label={`${def.headerName ? def.headerName.replace(/[iI][dD] /g, "") : ""}${isOptional(def.field) ? "" : "*"}`}
                                        name={def.field} sx={{ width: "100%" }} disabled={!shouldShowLinkedFields()} />
                                </div>
                        }
                    }
                    onInputChange={(event, searchTerm) => handleAutocompleteInputChangeWithDebounce(event, searchTerm, def.field)}
                    onFocus={(event) => handleFocus(event, def.field)}
                    onClose={() => handleAutocompleteClose(def.field)}
                    onChange={(event, value) => handleAutocompleteSelect(event, value, def.field)}
                    loading={autocompleteFieldsLoadingMap[def.field] ?? false} />
            }
        </div>
    }

    function renderEnumField(def: GridColDef): JSX.Element {
        return <div key={`${def.field}-field-wrapper`}>
            { !booleanDisabledFieldNames.includes(def.field) && defaultEnumValues[def.field] &&
                <Autocomplete
                    key={`${def.field}-enum`}
                    filterOptions={x => x}
                    options={SchemaUtils.getEnumPossibilities(schema, def.field).map(option => option.name)}
                    defaultValue={defaultEnumValues[def.field] ? defaultEnumValues[def.field].name : ""}
                    renderInput={params => {
                            return <div className="fieldContainer">
                                    <TextField {...params} label={def.headerName} name={def.field} sx={{ width: "100%" }} />
                                </div>
                        }
                    }
                    disabled={editDisabledFields.includes(def.field)}
                    disableClearable
                    onChange={(event, value) => handleEnumSelect(event, value, def.field)}/>
            }
        </div>
    }

    function renderTextField(def: GridColDef): JSX.Element {
        return <div key={`${def.field}-field-wrapper`}>
            { !booleanDisabledFieldNames.includes(def.field) &&
                <div key={`${def.field}-field`} className="fieldContainer">
                        <TextField name={def.field} inputMode={getInputMode(def.field)}
                            error={isInvalidField(def.field)} helperText={getInvalidFieldErrorMessage(def.field)} label={`${def.headerName}${isOptional(def.field) ? "" : "*"}`}
                            variant="outlined" sx={{ width: "100%" }} defaultValue={getSimpleDefaultValue(def.field)}
                            disabled={editDisabledFields.includes(def.field)} />
                </div>
            }
        </div>
    }

    function renderDateField(def: GridColDef): JSX.Element {
        let dateConfiguration = schema.additionalInfo?.[def.field]?.dateConfiguration;
        return <div key={`${def.field}-field-wrapper`}>
            { !booleanDisabledFieldNames.includes(def.field) &&
                <div key={`${def.field}-date`} className="fieldContainer">
                    <DatePicker disableFuture={dateConfiguration?.disableFuture ?? false} 
                        disablePast={dateConfiguration?.disablePast ?? false}
                        defaultValue={isOptional(def.field) ? undefined : getSimpleDefaultValue(def.field) ?? dayjs()}
                        name={def.field} label={`${def.headerName}${isOptional(def.field) ? "" : "*"}`} sx={{ width: "100%" }}
                        disabled={editDisabledFields.includes(def.field)}
                        slotProps={{ textField: { error: isInvalidField(def.field), helperText: getInvalidFieldErrorMessage(def.field) } }} />
                </div>
            }
        </div>
    }

    function renderBooleanField(def: GridColDef): JSX.Element {
        // boolean-enabled fields - if specified, insert a function that will disable the given fields
        // based on the value of this boolean field
        let booleanEnabledGroup = schema.booleanEnabledGroups?.find(group => group.field === def.field);
        let toggleFunction = undefined;
        if (booleanEnabledGroup) {
            const nonNullBooleanEnabledGroup = booleanEnabledGroup;
            toggleFunction = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                // Enable
                if (checked === nonNullBooleanEnabledGroup.value) {
                    setBooleanDisabledFieldNames(oldFields => {
                        let newFields = oldFields.filter(fieldName => !nonNullBooleanEnabledGroup.fieldGroup.includes(fieldName));
                        return newFields;
                    })
                } else {
                    // Disable
                    setBooleanDisabledFieldNames(oldFields => {
                        let newFields = [...oldFields];
                        nonNullBooleanEnabledGroup.fieldGroup.forEach(fieldName => {
                            if (!newFields.includes(fieldName)) {
                                newFields.push(fieldName);
                            }
                        });
                        return newFields;
                    })
                }
            };
        }
    
        return <div key={`${def.field}-field-wrapper`}>
            { !booleanDisabledFieldNames.includes(def.field) &&
                <div key={`${def.field}-label`} className="fieldContainer">
                    <FormControlLabel label={def.headerName} sx={{ width: "100%" }}
                        control={
                            <Checkbox key={`${def.field}-checkbox`} name={def.field}
                                defaultChecked={getCheckboxDefaultChecked(def.field)} onChange={toggleFunction}
                                disabled={editDisabledFields.includes(def.field)} />
                        }/>
                </div>
            }
        </div>
    }

    function renderTextAreaField(def: GridColDef): JSX.Element {
        return <div key={`${def.field}-field-wrapper`} className="textAreaContainer">
            { !booleanDisabledFieldNames.includes(def.field) &&
                <div key={`${def.field}-textarea`}>
                    <TextField label={`${def.headerName}${isOptional(def.field) ? "" : "*"}`} sx={{ width: "100%" }} name={def.field}
                        multiline rows={3} defaultValue={getSimpleDefaultValue(def.field)}
                        disabled={editDisabledFields.includes(def.field)} />
                </div>
            }
        </div>
    }
    
    function getColDefByFieldName(fieldName: string): GridColDef {
        return getColDef().find(def => def.field === fieldName) ?? {} as GridColDef;
    }

    // Discriminator function for field rendering
    function renderFieldByName(fieldName: string): JSX.Element {
        const colDef = getColDefByFieldName(fieldName);

        if (schema.additionalInfo?.[fieldName]?.fieldLinkInfo) {
            return renderLinkedField(colDef);
        }

        if (schema.additionalInfo?.[fieldName]?.possibilities) {
            return renderEnumField(colDef);
        }

        if (schema.additionalInfo?.[fieldName]?.inputMode === "text") {
            return renderTextAreaField(colDef);
        }

        if (colDef.type === "date") {
            return renderDateField(colDef);
        }

        if (colDef.type === "boolean") {
            return renderBooleanField(colDef);
        }

        return renderTextField(colDef);
    }
    
    function getModalContents() {
        if (mode.defaultValuesRequired && !defaultValues) {
            return <div> Qualcosa è andato storto nel caricare il modale. Impossibile caricare i valori di default dei campi. </div>
        }

        return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <form onSubmit={addItem} ref={addItemFormRef}>
                <div className="fields">
                    {
                        getFieldNamesInRenderOrder().map(renderFieldByName)
                    }
                </div>
                <div className="groupedFields">
                    {
                        getRenderedFieldGroups()
                    }
                </div>
                <div className="actions">
                    <Button type="submit" variant="outlined" disabled={processingRequest}>Invia</Button>
                    <Button color="error" variant="outlined" disabled={processingRequest} onClick={() => closeAddItemModal()}>Annulla</Button>
                </div>
            </form>
        </LocalizationProvider>
    }

    return (
        <>
            <ModalBase title={`${mode.modalTitleVerb} ${schema.displaySingular}`} open={open} parentCloseFunction={closeAddItemModal}>
                <div className="addItemModal">
                    { getModalContents() }
                </div>
            </ModalBase>
        </>
    )
}
